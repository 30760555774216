import React, { useEffect } from "react";
import logo from "../../assets/images/beerpay/logo.png";
import StickyMenu from "../../lib/StickyMenu";
import Navigation from '../Navigation';

function HomeOneHeader({
  lang,
  darkEnable = false,
  action,
  langEnabled = false,
  changeMode,
  changeModeLan,
  dark,
  className,
}) {
  useEffect(() => {
    StickyMenu();
  });
  return (
    <header className={`appie-header-area appie-sticky ${className || ""}`}>
      <div className="container">
        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <a href="/">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
             
            </div>
            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
              <div className="appie-btn-box text-right">
              <div className="appie-header-main-menu">
                <Navigation />
                {/* <a className="main-btn ml-30" href="/company">
                  Para sua Empresa
                </a>
                <a className="login-btn" href="/forgotpassword">
                  <i className="fal fa-user" /> Recuperar Senha
                </a> */}
              </div>
                <div
                  onClick={(e) => action(e)}
                  className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                >
                  <i className="fa fa-bars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HomeOneHeader;
