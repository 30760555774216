import React, { useEffect } from 'react';

function Company() {    
    useEffect(()=>{
        window.location.replace('http://beerpay-dot-toquegole.rj.r.appspot.com');
    },[])
    return (
        <>
      
        </>
    );
}

export default Company;
