import React, { useState } from "react";
import useToggle from "../../Hooks/useToggle";
import { sendPasswordRecover } from "../../services/userService/userService";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import HomeOneHeader from "../HomeOne/HomeOneHeader";
import Forms from "./Forms";

function ForgotPassword() {
  const [drawer, drawerAction] = useToggle(false);
  const [messages, setMessages] = useState([]);

  const onSumit = (event) => {
    setMessages([]);
    event.preventDefault();
    console.warn(event.target[0].value);
    if (!event.target[0] || !event.target[0].value)
      return setMessages([{ text: "E-mail inválido.", type: "error" }]);
    sendPasswordRecover({ email: event.target[0].value })
      .then((result) => {
        console.log("result", result.message);
        if (result.message)
          setMessages([{ text: result.message, type: "success" }]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
      <HomeOneHeader drawer={drawer} action={drawerAction.toggle} />
      {/* <HeroNews
                title="Blogs"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                ]}
            /> */}
      <Forms onSumit={onSumit} messages={messages} />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default ForgotPassword;
