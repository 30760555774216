import React from "react";
import aboutThumb from "../../assets/images/beerpay/IMG_05.png";

function Mentoria() {
  return (
    <>
      <section className="appie-about-area mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="appie-about-box wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="about-thumb">
                      <img src={aboutThumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="appie-about-content">
                      <span>Marketing</span>
                      <h3 className="title">
                        Tenha seu próprio canal de marketing
                      </h3>
                      <p>
                      Não fique refém das redes sociais, construa uma conexão duradoura e satisfatória
                       com seus clientes e os mantenha sempre atualizados sobre tudo o que está acontecendo
                        em seu estabelecimento, diretamente na tela do celular!
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="appie-about-service mt-30">
                          <div className="icon">
                            <i className="fal fa-check" />
                          </div>
                          <h4 className="title">Traga novos clientes</h4>
                          <p>
                            Com as ferramentas de marketing da BeerPay, você consegue atingir
                            pessoas de toda a cidade, se comunicando diretamente com elas. 
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="appie-about-service mt-30">
                          <div className="icon">
                            <i className="fal fa-check" />
                          </div>
                          <h4 className="title">Marketing integrado</h4>
                          <p>
                            {" "}
                            Cupons de desconto, ranking dos maiores clientes, pague um chopp à 
                            um amigo, divulgue eventos e promoções, tudo dentro da BeerPay.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="appie-about-service mt-30">
                          <div className="icon">
                            <i className="fal fa-check" />
                          </div>
                          <h4 className="title">Estratégias de comunicação</h4>
                          <p>
                          Envie ofertas personalizadas, com foco nas preferências individuais 
                          dos seus clientes, via notificações push ou WhatsApp.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="appie-about-service mt-30">
                          <div className="icon">
                            <i className="fal fa-check" />
                          </div>
                          <h4 className="title">Crescimento e previsibilidade</h4>
                          <p>
                          Fortaleça o relacionamento com o cliente através de estratégias inovadoras 
                          que impulsionam a recorrência e aumentam o seu faturamento. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mentoria;
