import React, { useState } from "react";
import heroThumb from "../../assets/images/beerpay/IMG_01.png";
import PopupVideo from "../PopupVideo";

function HeroHomeFour() {
  const [showVideo, setVideoValue] = useState(false);
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue(!showVideo);
  };
  return (
    <>
      {showVideo && (
        <PopupVideo
          videoSrc="https://www.instagram.com/reel/C0xtHI6vlnr/embed"
          handler={(e) => handleShowVideo(e)}
        />
      )}
      <section className="appie-hero-area appie-hero-5-area appie-hero-3-area">
        <div className="container">
          <div className="row  justify-content-center">
            <div className="col-lg-10">
              <div className="appie-hero-content text-center">
                <h1 className="appie-title">
                  A popularização do autosserviço de chope{" "}
                </h1>
                <p>Automação rápida e descomplicada</p>
                <div className="hero-btns">
                  <a
                    target="_blank22"
                    className="main-btn"
                    href="https://api.whatsapp.com/send/?phone=5554935059228&text=Quero a Beerpay no meu bar&type=phone_number&app_absent=0"
                  >
                    Compre agora
                  </a>
                  <a
                    onClick={(e) => handleShowVideo(e)}
                    className="appie-video-popup"
                    href="https://drive.google.com/file/d/1TdR_dTJPlQry5ywi_ZznD2l-VUsmw_Cb/view?t=5"
                  >
                    <i className="fas fa-play"></i> Sinta a Experiência
                  </a>
                </div>
                <div
                  className="thumb mt-80 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <img src={heroThumb} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHomeFour;
