import React from "react";
import shape13 from "../../assets/images/beerpay/icon_beerpay.png";
import trafficThumb from "../../assets/images/beerpay/IMG_04.png";
import logo from "../../assets/images/beerpay/logo.png";

function Aplicativo() {
  return (
    <>
      <section
        className="appie-features-area-2 appie-features-area-5 pt-90 pb-100"
        id="features"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <div className="content">
                  <h3 className="appie-title">Aplicativo</h3>
                  <img src={logo} alt="" />
                </div>
                <p>(Sem cartão)</p>
              </div>
            </div>
          </div>
          <div className="row mt-30 align-items-center">
            <div className="col-lg-6">
              <div className="appie-features-boxes ">
                <div className="appie-features-box-item item-3 appie-features-box-5-item">
                  <h4 className="title">Cadastro fácil e seguro</h4>
                  <p>Cadastro rápido e validação de maioridade.</p>
                </div>
                <div className="appie-features-box-item item-3 appie-features-box-5-item">
                  <h4 className="title">Compra com um clique</h4>
                  <p>Pagamento facilitado via PIX e cartão de crédito.</p>
                </div>
                <div className="appie-features-box-item item-3 appie-features-box-5-item">
                  <h4 className="title">Carteira global</h4>
                  <p>Seus créditos têm validade em todos estabelecimentos com tecnologia BeerPay.</p>
                </div>
                {/* <div className="appie-features-box-item item-3 appie-features-box-5-item">
                  <h4 className="title">
                    Tudo em um só lugar
                    <p>Descubra quais cervejas estão disponíveis, desbloqueie torneiras e sirva-se o quanto quiser.
                      Você faz tudo dentro do aplicativo da BeerPay.
                    </p>
                  </h4>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-features-thumb wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <img src={trafficThumb} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-1">
          <img src={shape13} alt="" />
        </div>
        <div className="features-shape-2">
          <img src={shape13} alt="" />
        </div>
        <div className="features-shape-3">
          <img src={shape13} alt="" />
        </div>
      </section>
    </>
  );
}

export default Aplicativo;
