import React, { useState } from "react";
import useToggle from "../../Hooks/useToggle";
import { setPasswordRecover } from "../../services/userService/userService";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import HomeOneHeader from "../HomeOne/HomeOneHeader";
import Drawer from "../Mobile/Drawer";
import Forms from "./Forms";

function SetPassword(props) {
  console.warn("props", props);
  const { match } = props;
  const { params } = match;
  const [drawer, drawerAction] = useToggle(false);
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState({
    password: "",
    passwordConfirm: "",
  });

  const onSumit = (event) => {
    event.preventDefault();
    setMessages([]);
    console.warn(event.target[0].value);
    if (data.password !== data.passwordConfirm)
      return setMessages([
        {
          text: "Os campos devem ser preenchidos com a mesma senha.",
          type: "error",
        },
      ]);

    setPasswordRecover({ password: data.password, token: params.token })
      .then((result) => {
        console.log("result", result.message);
        if (result.message)
          setMessages([{ text: result.message, type: "success" }]);
      })
      .catch((err) => {
        if (err.message)
          setMessages([
            {
              text: err.message,
              type: "error",
            },
          ]);
      });
  };

  const onChange = (event) => {
    setMessages([]);
    if (event.target && event.target.value)
      setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader drawer={drawer} action={drawerAction.toggle} />
      {/* <HeroNews
                title="Blogs"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                ]}
            /> */}
      <Forms
        onSumit={onSumit}
        onChange={onChange}
        messages={messages}
        data={data}
      />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default SetPassword;
