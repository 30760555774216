import React, { useState } from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import FooterHomeOne from "../HomeOne/FooterHomeOne";
import HomeOneHeader from "../HomeOne/HomeOneHeader";
import Forms from "./Forms";

function DeleteAccount() {
  const [drawer, drawerAction] = useToggle(false);
  const [messages, setMessages] = useState([]);

  const onSumit = (event) => {

    event.preventDefault();
    setMessages([{ text: "Em breve instruções de como deletar sua conta chegaram no seu email.", type: "success" }]);
  };

  return (
    <>
      {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
      <HomeOneHeader drawer={drawer} action={drawerAction.toggle} />
      {/* <HeroNews
                title="Blogs"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                ]}
            /> */}
      <Forms onSumit={onSumit} messages={messages} />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default DeleteAccount;
