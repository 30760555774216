import React from "react";

function Terms() {
  return (
    <>
      <div className="single-post-area">
        <h4 className="article-title">Política de Privacidade</h4>
        <p>
          1. A privacidade de nossos visitantes / usuários em nosso site e
          aplicativos são de importância para a Beerpaye nosso compromisso.
          Através deste link é possível entender tudo o que faremos com suas
          informações pessoais.
        </p>
        <p>
          2. Para a utilização de nossos aplicativos, sites e solicitação de
          formulários, são utilizados COOKIES, de acordo com a política descrita
          neste documento.
        </p>
        <p>
          3. Abaixo, seguem as informações coletadas em nossos sites e
          aplicativos, que são armazenados e utilizados:
        </p>
        <p>
          3.1. Informações sobre o seu computador, incluindo seu endereço de IP,
          localização geográfica, tipo e versão de navegador e sistema
          operacional;
        </p>
        <p>
          3.2. Informações sobre suas visitas e uso deste site, incluindo fontes
          de referência, duração da navegação, visualização e tempo nas
          respectivas páginas, com o caminho da navegação;
        </p>
        <p>
          3.3. Informação, como seu endereço de e-mail, endereço, telefone,
          quando você digita em nosso site, solicitando registro para
          finalidades de acesso, orçamento, recebimento de newsletter /
          informativo e contato;
        </p>
        <p>
          3.4. Informação que você digita ao criar um perfil em nosso site /
          aplicativos – por exemplo, seu nome, fotos de perfil, sexo, data de
          nascimento, profissão, empresa, interesses e informações
          profissionais;
        </p>
        <p>
          3.5. Informações geradas ao usar nosso site / aplicativos, incluindo
          quando, com que frequência e em que circunstâncias você o utiliza;
        </p>
        <p>
          3.6. Informações relacionadas a tudo que você compra, serviços que
          usa, transações que exclusivamente realiza através do nosso site,
          incluindo nome, endereço, número de telefone, empresa, inscrições de
          CNPJ e Inscrição Estadual, endereço de e-mail e dados do cartão de
          crédito;
        </p>
        <p>
          3.7. Informações que você publica através de nosso site, incluindo seu
          nome de usuário, endereço e conteúdo da publicação;
        </p>
        <p>
          3.8. Informações contidas em quaisquer comunicações que você nos envia
          por e-mail ou através do nosso site, incluindo o conteúdo da
          comunicação;
        </p>
        <p>3.9. Qualquer outra informação pessoal que você nos enviar.</p>
        <p>
          4. As informações pessoais que nos são enviadas por meio de nosso site
          serão usadas para os fins especificados nesta política ou nas páginas
          relevantes do site e aplicativo. Podemos usar suas informações
          pessoais para o seguinte:
        </p>
        <p>4.1. Administrar nosso site nossos negócios;</p>
        <p>4.2. Personalizar o nosso site para você;</p>
        <p>4.3. Possibilitar o uso dos serviços disponíveis em nosso site;</p>
        <p>4.4. Enviar produtos adquiridos através do nosso site;conforme</p>
        <p>
          4.5. Enviar extratos, faturas e lembretes de pagamento, bem como
          coletar seus pagamentos;
        </p>
        <p>.6. Prestar serviços adquiridos através do nosso site;</p>
        4.7. Enviar comunicações comerciais que não sejam de marketing;
        <p>
          4.8. Enviar notificações por e-mail solicitadas especificamente por
          você;
        </p>
        <p>
          4.9. Enviar nossa newsletter / informativo por e-mail, caso você a
          tenha solicitado (você pode nos informar a qualquer momento se não
          quiser mais receber a newsletter / informativo);
        </p>
        <p>
          4.10. Enviar comunicações de marketing relacionados aos nossos
          negócios ou aos negócios de terceiros (grupo), cuidadosamente
          selecionados que acreditamos ser do seu interesse, por correio ou,
          onde você especificamente concordou com isso, por e-mail ou tecnologia
          semelhante (você pode nos informar a qualquer momento se não mais
          quiser receber comunicações de marketing);
        </p>
        <p>
          4.11. Fornecer a terceiros informações estatísticas sobre nossos
          usuários (mas esses terceiros não poderão identificar nenhum usuário
          individual a partir dessa informação; 4.12. Lidas com perguntas e
          reclamações feitas por você ou sobre você em relação ao nosso site;
        </p>
        <p>4.13. Manter nosso sites / aplicativos seguro e evitar fraudes;</p>
        <p>
          4.14. Verificar a conformidade com os termos e condições que regem o
          uso do nosso site (incluindo o monitoramento de mensagens privadas
          enviadas por meio do serviço de mensagens privadas do nosso site);
        </p>
        <p>
          5. Podemos divulgar suas informações pessoais a qualquer um de nossos
          funcionários, executivos, seguradoras, consultores, profissionais,
          agentes, fornecedores ou subcontratados conforme razoavelmente
          necessários para os fins estabelecidos nesta política. Podemos
          divulgar suas informações pessoais a qualquer membro de nosso grupo de
          empresas (isso significa nossas subsidiárias, empresas do grupo e
          holdings), conforme razoavelmente necessário para os fins
          estabelecidos nesta política. Podemos divulgar suas informações
          pessoais:
        </p>
        <p>5.1. Na medida em que somos obrigados a fazê-lo por lei;</p>
        <p>
          5.2. Em relação a qualquer processo judicial em andamento ou
          potencial;
        </p>
        <p>
          5.3. Para estabelecer, exercer ou defender nossos direitos legais
          (incluindo fornecer informações a terceiros para fins de prevenção de
          fraudes e redução do risco de crédito); 5.4. Ao comprador (ou
          comprador em potencial) de qualquer negócio ou ativo que estejamos
          vendendo (ou contemplando vender);
        </p>
        <p>
          5.5. A qualquer pessoa que acreditemos razoavelmente que possa
          solicitar a um tribunal ou outra autoridade competente a divulgação
          dessas informações pessoais, quando, em nossa opinião razoável, for
          provável que tal tribunal ou autoridade ordene a divulgação dessas
          informações pessoais.
        </p>
        <p>6. Transferências internacionais de dados:</p>
        <p>
          6.1. As informações que coletamos podem ser armazenadas, processadas e
          transferidas entre qualquer um dos países em que operamos, a fim de
          nos permitir usar as informações de acordo com esta política;
        </p>
        <p>
          6.2. As informações que coletamos podem ser transferidas para os
          seguintes países que não possuem leis de proteção de dados
          equivalentes às vigentes no Espaço Econômico Europeu: Estados Unidos
          da América, Rússia, Japão, China e India;
        </p>
        <p>
          6.3. As informações pessoais que você publica em nosso site ou
          aplicativo, enviando para a publicação em nosso site podem estar
          disponíveis, através da internet, em todo o mundo. Não podemos impedir
          o uso ou uso indevido de tais informações por terceiros.
        </p>
        <p>7. Retenção de informações pessoais:</p>
        <p>
          7.1. Este ponto 7. Define nossas políticas e procedimentos de retenção
          de dados, projetados para ajudar a garantir o cumprimento de nossas
          obrigações legais em relação à retenção e exclusão de informações
          pessoais;
        </p>
        <p>
          7.2. As informações pessoais que processamos para qualquer propósito
          ou propósitos não devem ser mantidas por mais tempo do que o
          necessários para esses propósitos. 7.3. Não obstante as outras
          disposições, reteremos documentos (incluindo documentos eletrônicos)
          que contenham dados pessoais: a) Na medida em que somos obrigados a
          fazê-los por lei; b) Se acreditarmos que os documentos podem ser
          relevantes para qualquer processo judicial em andamento ou potencial);
          e c) Para estabelecer, exercer ou defender nossos direitos legais
          (incluindo fornecer informações a terceiros para fins de prevenção de
          fraudes e redução do risco de crédito).
        </p>
        <p>8. Segurança de suas informações pessoais:</p>
        <p>
          8.1. Tomaremos as devidas precauções técnicas e organizacionais para
          evitar a perda, mau uso ou alteração de suas informações pessoais;
        </p>
        <p>
          8.2. Armazenaremos todas as suas informações pessoais fornecidas em
          nossos servidores seguros (protegidos por senha e firewall);
        </p>
        <p>
          8.3. Todas as transações financeiras eletrônicas realizadas através do
          nosso site serão protegidas por tecnologia de criptografia;
        </p>
        <p>
          8.4. Você reconhece que a transmissão de informação pela internet é
          inerentemente insegura e que não podemos garantir a segurança dos
          dados enviados pela internet;
        </p>
        <p>
          8.5. Você é responsável por manter em sigilo a senha usada para
          acessar nosso site; não solicitaremos sua senha (exceto quando você
          fizer login em nosso site);
        </p>
        <p>
          9. ALTERAÇÕES: Podemos atualizar esta política periodicamente, através
          de publicação de uma nova versão em nosso site. Você deve verificar
          esta página ocasionalmente para garantir que compreende quaisquer
          alterações nesta política. Podemos notifica-lo sobre alterações nesta
          política por e-mail ou através do sistema de mensagens privadas em
          nosso site.
        </p>
        <p>
          10. DIREITO: Você pode nos instruir a fornecer qualquer informação
          pessoal que detenhamos sobre você, desde que comprovado sua identidade
          mediante envio de carteira de identidade, comprovante de residência;
        </p>
        <p>
          10.1. Podemos reter as informações pessoais solicitadas na extensão
          permitida por lei;
        </p>
        <p>
          10.2. Você pode nos instruir a qualquer momento para não processar
          suas informações pessoais para fins de marketing;
        </p>
        <p>
          11. SITE DE TERCEIROS: Nosso site inclui links para e detalhes de site
          de terceiros. Não temos controle sobre e não somos responsáveis pelas
          políticas e práticas de privacidade de terceiros;
        </p>
        <p>
          12. Atualização de informações: Informe-nos se as informações pessoais
          que mantemos sobre você precisam ser corrigidas ou atualizadas;
        </p>
        <p>
          13. COOKIES: Nosso site usa cookies. Um cookie é um arquivo que contém
          um identificador (uma sequência de letras e números) que é enviado por
          um servidor na WEB para um navegador e armazenado pelo navegador. O
          identificador é então enviado de volta ao servidor toda vez que o
          navegador solicita uma página do servidor. Os cookies podem ser
          cookies “persistentes” ou cookies de “sessão”. Um cookie persistente
          será armazenado por um navegador e permanecerá válido até a data de
          vencimento definida, a menos que seja excluído pelo usuário antes da
          data de vencimento; um cookie de sessão, por outro lado, expirará no
          final da sessão do usuário, mas as informações pessoais que
          armazenamos sobre você podem estar vinculadas às informações
          armazenadas e obtidas a partir de cookies;
        </p>
        <p>
          13.1. Normalmente a maioria dos navegadores permite que você se recuse
          a aceitar cookies.
        </p>
        <p>
          13.2. O bloqueio de todos os cookies poderá causar um impacto negativo
          na usabilidade de nosso site, podendo não utilizar todos os recursos
          do site;
        </p>
        <p>
          13.3. Você pode excluir os cookies que já estão armazenados no seu
          computador, este ato também pode prejudicar a navegabilidade e
          usabilidade do site em geral.
        </p>
      </div>
    </>
  );
}

export default Terms;
