import React, { useState } from "react";
import thumb from "../../assets/images/features-thumb-1.png";
import shapeSix from "../../assets/images/shape/shape-6.png";
import shapeSeven from "../../assets/images/shape/shape-7.png";
import shapeEight from "../../assets/images/shape/shape-8.png";

function FeaturesHomeOne({ className }) {
  const [tab, setTab] = useState("setting");
  const handleClick = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section
      className={`appie-features-area pt-100 ${className}`}
      id="features"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="appie-features-tabs-btn">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  onClick={(e) => handleClick(e, "setting")}
                  className={`nav-link ${tab === "setting" ? "active" : ""}`}
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  href="#v-pills-home"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <i className="fas fa-cog" /> Expansão de Negócios
                </a>
                <a
                  onClick={(e) => handleClick(e, "report")}
                  className={`nav-link ${tab === "report" ? "active" : ""}`}
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <i className="fas fa-exclamation-triangle" /> Tecnologia de
                  Ponta
                </a>
                <a
                  onClick={(e) => handleClick(e, "notice")}
                  className={`nav-link ${tab === "notice" ? "active" : ""}`}
                  id="v-pills-messages-tab"
                  data-toggle="pill"
                  href="#v-pills-messages"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <i className="fas fa-bell" /> Controle Autônomo
                </a>
                <a
                  onClick={(e) => handleClick(e, "app")}
                  className={`nav-link ${tab === "app" ? "active" : ""}`}
                  id="v-pills-settings-tab"
                  data-toggle="pill"
                  href="#v-pills-settings"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  <i className="fas fa-lock" />Suporte Excepcional
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className={`${
                  tab === "setting" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center wow animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content wow animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Expansão</span>
                      <h3 className="title">Negócios Ilimitados</h3>
                      <p>
                        Beerpay não é apenas uma solução; é uma oportunidade.
                        Oferecemos às cervejarias a chance de ampliar suas
                        fronteiras de negócios, levando o sabor inigualável do
                        seu chope para locais antes inexplorados, como
                        conveniências, postos de combustíveis e hotéis.
                      </p>
                      {/* <Link className="main-btn" to="/about-us">
                        Learn More
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "report" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      {/* <span>Tecnologia de Ponta</span> */}
                      <h3 className="title">Tecnologia de Ponta</h3>
                      <p>
                        Beerpay representa a vanguarda da tecnologia de vending
                        machines para chope. Equipado com recursos avançados de
                        controle e conectividade, ele oferece às cervejarias o
                        controle total sobre suas operações de venda.
                      </p>
                      {/* <a className="main-btn" href="#">
                        Learn More
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "notice" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      {/* <span>Controle</span> */}
                      <h3 className="title">
                        Controle<br />
                        Autônomo para o 
                        Usuário
                      </h3>
                      <p>
                        Nosso aplicativo inovador coloca o poder nas mãos dos
                        consumidores. Com a facilidade de um toque, eles podem
                        se servir, personalizando sua experiência de acordo com
                        suas preferências.
                      </p>
                      {/* <a className="main-btn" href="#">
                        Learn More
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "app" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      {/* <span>Custom Reacyions</span> */}
                      <h3 className="title">
                        Inovação  <br /> Contínua e  <br /> Suporte Excepcional
                      </h3>
                      <p>
                        Estamos comprometidos com a inovação constante. Mantemos
                        nossos clientes à frente das tendências do mercado,
                        oferecendo atualizações regulares e suporte excepcional.
                        Estamos aqui não apenas para vender uma tecnologia, mas
                        para construir parcerias duradouras e bem-sucedidas.
                      </p>
                      <a className="main-btn" href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-shape-1">
        <img src={shapeSix} alt="" />
      </div>
      <div className="features-shape-2">
        <img src={shapeSeven} alt="" />
      </div>
      <div className="features-shape-3">
        <img src={shapeEight} alt="" />
      </div>
    </section>
  );
}

export default FeaturesHomeOne;
