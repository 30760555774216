import React from "react";
import logo from "../../assets/images/beerpay/logo.png";

function ProjectHomeThree({ className }) {
  return (
    <>
      <section className={`appie-project-3-area pb-100 ${className} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                appie-project-3-box
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="text">
                  <h4 className="title">Fale com a</h4>
                  <img className="logo" src={logo}></img>
                </div>
                <a className="main-btn" target="_blank" href="https://api.whatsapp.com/send/?phone=5554935059228&text=Quero a Beerpay no meu bar&type=phone_number&app_absent=0">
                  WhatsApp <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectHomeThree;
