import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import ProjectHomeThree from '../HomeThree/ProjectHomeThree';
import Drawer from '../Mobile/Drawer';
import Aplicativo from './Aplicativo';
import FaqCompany from './FaqCompany';
import Header from './Header';
import HeroHomeFour from './HeroHomeFour';
import Mentoria from './Mentoria';
import SellMore from './SellMore';


function Company() {    
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroHomeFour />
            {/* <ModeloNegocio /> */}
            <SellMore />
            <Aplicativo />
            {/* <AplicativoNew /> */}
            {/* <Administrativo /> */}
            {/* <User /> */}
            {/* <SellEvenMore /> */}
            
            {/* <Clientes className="pt-100" /> */}
            {/* <Services  className="pt-190"  /> */}
            {/* <Automation  className="pt-190"  /> */}
            <Mentoria className="pt-100" />
            {/* <Consumo className="pt-190" /> */}
            {/* <FeaturesHomeFour /> */}
            {/* <CounterArea style={{ backgroundColor: '#EEF1F6' }} /> */}
            {/* <TestimonialHomeOne /> */}
            {/* <VideoPlayerHomeTwo className="pt-100" /> */}
            {/* <PricingHomeOne /> */}
            <FaqCompany className="home-four-project" />
            <ProjectHomeThree className="home-four-project" />
            <FooterHomeOne className="home-four-footer" />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default Company;
