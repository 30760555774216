import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import Terms from './Terms';
// import BlogSideBar from './BlogSideBar';
import HeaderNews from '../HomeOne/HomeOneHeader';
// import HeroNews from '../News/HeroNews';

function Privacy() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            {/* <HeroNews
                title="Blog"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Blogs' },
                    { link: '/news/single-news', title: 'Blog' },
                ]}
            /> */}
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <Terms />
                        </div>
                        <div className="col-lg-4 col-md-5">
                            {/* <BlogSideBar /> */}
                        </div>
                    </div>
                </div>
            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Privacy;
