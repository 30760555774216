import React from "react";
import aboutThumb3 from "../../assets/images/beerpay/IMG_03.png";
import logo from "../../assets/images/beerpay/logo.png";
import aboutThumb from "../../assets/images/beerpay/marketing001.png";

function SellMore() {
  return (
    <>
      <section className="appie-about-3-area" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="appie-about-thumb-3 wow animated fadeInLeft"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <img src={aboutThumb} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-traffic-title">
                <div className="content">
                  <h3 className="title">Venda mais com</h3>
                  <img src={logo} alt="" />
                </div>
                <p>
                  Mais do que automação: faça seu bar faturar ainda mais!
                </p>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      {/* <i className="fas fa-cog" /> */}
                      {/* <img src={icon5} alt="" /> */}
                      <i class="fas fa-ticket-alt"></i>
                    </div>
                    <h5 className="title">Disponibilize cupons de desconto </h5>
                    <p>
                      Crie e envie cupons de desconto personalizados para seus clientes, incentivando a
                      compra e aumentando a fidelidade.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fa fa-bell"></i>
                    </div>
                    <h5 className="title">Envie notificações push</h5>
                    <p>
                    Mantenha seus clientes engajados com push notifications instantâneas: promoções,
                    eventos ou novidades no cardápio, diretamente na tela do usuário.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fas fa-hands-helping"></i>
                    </div>
                    <h5 className="title">Consultoria e treinamento incluso</h5>
                    <p>
                      Aproveite todas vantagens da automação: 
                      nossos especialistas te ajudam a implementar e
                      otimizar a solução de acordo com as necessidades
                      específicas do seu negócio.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fa fa-window-close"></i>
                    </div>
                    <h5 className="title">Atendimento sem cartão</h5>
                    <p>
                      Sem comandas e sem filas. 
                      Cadastro e pagamento
                      diretamente no app: autonomia para seu bar e 
                      seu cliente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-100 flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              <div className="appie-traffic-title">
                <div className="content">
                  <h3 className="title">Gerenciamento inteligente</h3>
                </div>
                <p>
                  Acesso remoto e atualizações em tempo real: 
                  controle do seu negócio mesmo à distância.
                                  </p>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fas fa-dollar-sign"></i>
                    </div>
                    <h5 className="title">Fluxo de pagamentos</h5>
                    <p>
                    Gerencie as finanças do seu negócio de forma eficiente e segura.
                    Acompanhe todas as transações financeiras em tempo real, 
                    dentro da própria plataforma. 
                    
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fas fa-box"></i>
                    </div>
                    <h5 className="title">Controle de estoque</h5>
                    <p>
                    Adicione e remova barris, controle a quantidade, preço e receba 
                    alertas de reabastecimento.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fas fa-cloud-download"></i>
                    </div>
                    <h5 className="title">Atualização em tempo real</h5>
                    <p>
                    Sistema arquitetado em cloud computing, hospedado na Google,
                     gerando segurança e confiabilidade no tráfego instantâneo de 
                     informações.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="appie-traffic-service mb-30">
                    <div className="icon">
                      <i class="fas fa-users"></i>
                    </div>
                    <h5 className="title">Perfil de consumo</h5>
                    <p>
                      Identifique e explore o comportamento de consumo dos 
                      clientes e crie campanhas de marketing direcionadas 
                      para aumentar o faturamento e fidelidade.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-about-thumb-3 text-right wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <img src={aboutThumb3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SellMore;
