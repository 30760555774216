import React from "react";
import heroThumbOne from "../../assets/images/beerpay/experiencia.gif";
import shape13 from "../../assets/images/beerpay/icon_beerpay.png";
import logo from "../../assets/images/beerpay/logo.png";
// import shapeTwo from "../../assets/images/shape/shape-2.png";
// import shapeThree from "../../assets/images/shape/shape-3.png";
// import shapeFour from "../../assets/images/shape/shape-4.png";

function HeroHomeOne({ className }) {
  return (
    <>
      <section className={`appie-hero-area ${className || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <span>Bem-vindo a</span>
                <h1 className="appie-title">
                  <img src={logo} alt="" />
                </h1>
                {/* <h1 className="appie-title">Beerpay</h1> */}
                <p>
                  Aplicativo para encontrar, desbloquear e degustar cervejas
                  pela cidade! Baixe, desbloqueie e beba, com moderação!
                </p>
                <ul>
                  <li>
                    <a href="https://apps.apple.com/us/app/beerpay/id1545045568">
                      <i className="fab fa-apple" /> Download for iOS
                    </a>
                  </li>
                  <li>
                    <a className="item-2" href="https://play.google.com/store/apps/details?id=com.beerpayapp">
                      <i className="fab fa-google-play" /> Download for Android
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-thumb">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={heroThumbOne} alt="" />
                </div>
                {/* <div
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <img src={heroThumbTwo} alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shape-1">
          <img src={shape13} alt="" />
        </div>
        <div className="hero-shape-2">
          <img src={shape13} alt="" />
        </div>
        <div className="hero-shape-3">
          <img src={shape13} alt="" />
        </div>
      </section>
    </>
  );
}

export default HeroHomeOne;
