import React from "react";

function Forms({ onSumit, messages = [] }) {
  // console.warn("sendPasswordRecovery", props)
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-form">
                <h4>Esqueceu sua senha?</h4>
                <p>
                  Podemos enviar as instruções de recuperação no seu E-mail!
                </p>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`alert ${
                      message.type === "error"
                        ? "alert-danger"
                        : "alert-success"
                    }`}
                    role="alert"
                  >
                    {message.text}
                  </div>
                ))}

                <form onSubmit={onSumit} method="post" className="row">
                  <div className="col-md-12">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <div className="condition-check">
                      <input id="terms-conditions" type="checkbox" />
                      <label htmlFor="terms-conditions">
                        I agree to the <a href="#">Terms & Conditions</a>
                      </label>
                    </div>
                  </div> */}
                  <div className="col-md-12 text-right">
                    <input type="submit" name="submit" value="Enviar" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Forms;
