import React, { useEffect } from 'react';
import icon from '../assets/images/beerpay/icons/whatsapp.svg';
import TopToBottom from '../lib/TopToBottom';

function BackToTop({ className }) {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <>
            <div className={`back-to-top ${className || ''}`}>
                <a target="_blank" href="https://api.whatsapp.com/send/?phone=5554935059228&text=Quero a Beerpay no meu bar&type=phone_number&app_absent=0">
                {/* <i class="fa-brands fa-whatsapp"></i> */}
                <img src={icon} alt="" fill="#BCBCBC"/>
                </a>
            </div>
        </>
    );
}

export default BackToTop;
