import React, { useState } from "react";

function FaqCompany({ className }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };
  return (
    <>
      <section className={`appie-faq-area pb-95 pt-50 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Perguntas Frequentes</h3>
                <p>As dúvidas mais comuns estão aqui.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como funciona?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            A BeerPay é o sistema mais fácil e prático
                            disponível no mercado. Não necessita comandas nem
                            telas, o cadastro, pagamento e liberação das
                            torneiras é feito diretamente no aplicativo,
                            dispensando o uso de hardwares caros e que podem
                            causar problemas ou interrupções na operação. Tambem
                            é muito fácil de instalar, tenha o sistema rodando
                            em poucos minutos após a instalação.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Consigo instalar sozinho?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Sim. O sistema BeerPay é PnP (Plug and Play), isso
                            significa que ele é muito fácil de instalar, basta
                            plugar no sistema de chope já instalado, conectar o
                            cabo de internet, o cabo de energia e está pronto
                            para utilizar. Você também recebe um manual de
                            instalação e de boas práticas.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(4)}
                    className={`accrodion ${showQues === 4 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como vou saber quando trocar o barril?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 4 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            No painel administrativo Beerpay você faz o
                            gerenciamento de tudo o que está acontecendo no
                            sistema. Mude valores, estilos e saiba quantos
                            litros ainda têm nos barris com atualizações em
                            tempo real. Quer ainda mais comodidade? Você pode
                            solicitar ao sistema que emita avisos conforme o
                            barril se aproxima do fim (aos 5 e 2l, por exemplo).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(5)}
                    className={`accrodion ${showQues === 5 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como funciona o pagamento?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 5 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            O seu cliente pode carregar créditos diretamente no
                            aplicativo, via PIX e cartão de crédito ou
                            diretamente no balcão do seu bar, utilizando as
                            formas de pagamentos aceitas pelo seu
                            estabelecimento.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(6)}
                    className={`accrodion ${showQues === 6 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Pode servir quanto quiser?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 6 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Sim. Com a Beerpay, seu cliente tem liberdade para
                            experimentar todas as cervejas das torneiras antes
                            de decidir com qual ele vai encher o copo. O sistema
                            é seguro e a torneira bloqueia automaticamente em
                            segundo, evitando assim que outro cliente consuma os
                            créditos do que acabou de se servir.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(7)}
                    className={`accrodion ${showQues === 7 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como o sistema conta os ml?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 7 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            A Beerpay utiliza o que existe de mais moderno em
                            tecnologia de contagem de líquidos. Nosso sistema
                            oferece uma precisão de até 2 dígitos depois da
                            vírgula (473.15ml) e não necessita aferição.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(13)}
                    className={`accrodion ${showQues === 13 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>O sistema é seguro?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 13 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            O sistema da Beerpay é completamente seguro. Ele
                            utiliza uma arquitetura avançada baseada em
                            computação em nuvem, com dados armazenados em
                            servidores altamente seguros. Todas as informações
                            dos usuários são criptografadas para garantir sua
                            proteção contra acesso não autorizado. Além disso,
                            implementamos múltiplas camadas de segurança para
                            assegurar a integridade, estabilidade e
                            disponibilidade contínua do sistema.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => openQuestion(8)}
                    className={`accrodion ${showQues === 8 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Ainda preciso de garçons?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 8 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Não mais. Nosso sistema opera de maneira
                            completamente autônoma, tanto no servimento quanto
                            no processamento de pagamentos. No entanto, é
                            necessário que profissionais cuidem e verifiquem o
                            sistema periodicamente para garantir seu
                            funcionamento contínuo e eficiente. Isso proporciona
                            tranquilidade e segurança, mantendo o foco na
                            excelência do atendimento ao cliente.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(9)}
                    className={`accrodion ${showQues === 9 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Gera espuma?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 9 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Não. Nosso sistema não interfere na formação de
                            espuma. A torneira funciona exatamente como se não
                            houvesse nenhum dispositivo instalado. Além disso,
                            nosso manual de boas práticas assegura sempre um
                            serviço de qualidade superior, garantindo um
                            servimento perfeito em todas as ocasiões.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(10)}
                    className={`accrodion ${showQues === 10 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como funciona o cadastro no aplicativo?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 10 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            O processo de cadastro no nosso aplicativo BeerPay é
                            simples e direto. Seu cliente começa preenchendo
                            alguns dados básicos, o que permite acesso imediato
                            ao aplicativo. A partir daí, ele pode facilmente
                            adicionar créditos à sua conta e liberar as
                            torneiras de maneira conveniente e eficiente. Nosso
                            objetivo é proporcionar uma experiência fluida desde
                            o primeiro contato, garantindo praticidade e
                            controle total sobre o consumo de chope.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(11)}
                    className={`accrodion ${showQues === 11 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>
                          Como impedir menores de idade de usar o sistema?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 11 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Nossa prioridade é assegurar que apenas maiores de
                            idade tenham acesso ao BeerPay. Implementamos um
                            sistema robusto de validação de dados integrado com
                            a Receita Federal, o que garante que apenas
                            indivíduos legalmente autorizados possam criar
                            contas no aplicativo. Dessa forma, mantemos um
                            ambiente seguro e em conformidade com as
                            regulamentações, protegendo tanto nossos clientes
                            quanto seu negócios.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(12)}
                    className={`accrodion ${showQues === 12 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Como meu cliente libera a torneira?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 12 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Com o BeerPay, garantimos que liberar a torneira
                            seja uma experiência intuitiva e eficiente. Nosso
                            aplicativo mobile não apenas permite que seu cliente
                            libere as torneiras de maneira prática, mas também
                            oferece um controle completo sobre o servimento. Em
                            tempo real, ele pode acompanhar a quantidade exata
                            de mililitros servidos e o valor correspondente que
                            será gasto. Além disso, através do aplicativo, seu
                            cliente pode adicionar créditos facilmente,
                            proporcionando conveniência e controle total sobre
                            seu consumo. Também é possível verificar o histórico
                            detalhado de consumo, garantindo uma gestão
                            inteligente e transparente da operação de chopeira.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Ainda está com dúvidas?{" "}
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send/?phone=5554935059228&text=Quero a Beerpay no meu bar&type=phone_number&app_absent=0"
                  >
                    Manda um WhatsApp
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqCompany;
