import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/beerpay/logo.png";

function FooterHomeOne({ className }) {
  return (
    <>
      <section className={`appie-footer-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                {/* <p>
                                    Appie WordPress is theme is the last theme you will ever have.
                                </p> */}
                {/* <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a> */}
                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="https://www.instagram.com/beerpayapp/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    {/* <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              {/* <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Service">Service</Link>
                  </li>
                  <li>
                    <a href="#">Case Studies</a>
                  </li>
                  <li>
                    <Link to="/news">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  {/* <li>
                    <Link to="/about-us">Community</Link>
                  </li> */}
                  {/* <li>
                                        <a href="#">Resources</a>
                                    </li> */}
                  {/* <li>
                    <a href="#">Faqs</a>
                  </li> */}
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/deleteAccount">Delete Account</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope" /> support@beerpay.app
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fal fa-phone" /> (54)99999
                    </a>
                  </li> */}
                  {/* <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt" /> 442 Belle Terre
                                                St Floor 7, San Francisco, AV 4206
                                            </a>
                                        </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="apps-download-btn">
                  <ul>
                    <li>
                      <a href="https://apps.apple.com/us/app/beerpay/id1545045568">
                        <i className="fab fa-apple" /> Download for iOS
                      </a>
                    </li>
                    <li>
                      <a
                        className="item-2"
                        href="https://play.google.com/store/apps/details?id=com.beerpayapp"
                      >
                        <i className="fab fa-google-play" /> Download for
                        Android
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>Copyright © 2023 Beerpay. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
