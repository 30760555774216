import React from "react";
import logo from "../../assets/images/beerpay/logo_zero.png";

function Loader() {
  return (
    <div className="offcanvas-brand text-center mb-40">
      <img src={logo} class="w-25" alt="" />
    </div>
  );
}

export default Loader;
