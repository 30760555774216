import React, { useEffect } from "react";
import logo from "../../assets/images/beerpay/logo.png";
import StickyMenu from "../../lib/StickyMenu";

function Header({
  lang,
  darkEnable = false,
  action,
  langEnabled = false,
  changeMode,
  changeModeLan,
  dark,
  className,
}) {
  useEffect(() => {
    StickyMenu();
  });
  return (
    <header className={`appie-header-area appie-sticky ${className || ""}`}>
      <div className="container">
        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <a href="/">
                  <img src={logo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
              <div className="appie-header-main-menu">
                {/* <Navigation /> */}
              </div>
            </div>
            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
           
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
