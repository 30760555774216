import axios from "axios";
import config from "./userServiceConfig";
export const sendPasswordRecover = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.databaseURL + "/sendPasswordRecover ", data)
      .then((response) => {
        if (response.data.message) resolve(response.data);
        else reject(response.data.error);
      });
  });
};
export const setPasswordRecover = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(config.databaseURL + "/setPasswordRecover ", data)
      .then((response) => {
        if (response.data.message) resolve(response.data);
        else reject(response.data.error);
      })
      .catch((err) => {
        if (err.response.data) reject(err.response.data);
      });
  });
};
